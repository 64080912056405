import React from 'react';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';

const GradeDataTable = (props) => {
  const apiRef = useGridApiRef();
  const { gradeTableData: tableData, isAttendanceAudit } = props;

  const pinnedColumns = {
    left: isAttendanceAudit
      ? ['name']
      : ['watch', 'firstName', 'lastName', 'tags'],
  };

  return (
    <DataGridPro
      autoHeight
      apiRef={apiRef}
      rows={tableData.rows}
      columns={tableData.columns}
      isRowSelectable={() => false}
      initialState={{
        pinnedColumns: pinnedColumns,
      }}
    />
  );
};

export default GradeDataTable;
